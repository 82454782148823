<template>
  <div id="stars"></div>
  <div id="stars2"></div>
  <div id="stars3"></div>
  <div class="title">
    <h1>Cheatcat.com</h1>
    <h1>Work in progress</h1>
    <h1>Please, stand by.</h1>
    <vue-countdown :time="time" v-slot="{ days, hours, minutes }">
      <h1>{{ days }} days, {{ hours }} hours, {{ minutes }} minutes.</h1>
    </vue-countdown>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  name: 'StandBy',
  components: {
  VueCountdown
  },
  data() {
    const now = new Date();
    const newYear = new Date("03/20/2023");

    return {
      time: newYear - now,
    };
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
